import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import {
  Button,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
} from '@chakra-ui/react'
import { Order_By } from '../generated/graphql'
import {
  DOPPEL_DARK_CLICKABLE,
  DOPPEL_DARK_CLICKABLE_HOVER,
  MENU_LIST_ZINDEX,
} from '../utils/style'
import { useSelectedSortingMechanism } from '../pages/reports'
import { useSelectedPage } from '@/hooks/spoof_report_table_filters'

const SUPPORTED_ORDER_BY: Order_By[] = [Order_By.DescNullsLast, Order_By.AscNullsLast]

const SortDropdown = ({ supportedSortTypes, sortToDisplay }) => {
  const menuItems = []
  const [selectedSortingMechanism, setSelectedSortingMechanism] =
    useSelectedSortingMechanism()
  const [, setSelectedPage] = useSelectedPage()

  supportedSortTypes.forEach((sortingMechanism) => {
    for (const orderBy of SUPPORTED_ORDER_BY) {
      menuItems.push(
        <MenuItemOption
          fontSize={13}
          key={sortingMechanism + '_' + orderBy}
          onClick={() => {
            setSelectedSortingMechanism({
              sortingType: sortingMechanism,
              orderBy: orderBy,
            })
            setSelectedPage(1)
          }}
          value={sortingMechanism + '_' + orderBy}
        >
          {
            <HStack>
              <Icon as={orderBy.includes('asc') ? ChevronUpIcon : ChevronDownIcon} />

              <Text fontSize={12}>
                {sortToDisplay(sortingMechanism)}

                {orderBy.includes('asc') ? ': asc' : ': desc'}
              </Text>
            </HStack>
          }
        </MenuItemOption>,
      )
    }
  })

  return (
    <HStack>
      <Text fontSize="12px">Sort By: </Text>

      <Menu>
        <MenuButton
          _hover={{
            bgColor: DOPPEL_DARK_CLICKABLE_HOVER,
          }}
          as={Button}
          bgColor={DOPPEL_DARK_CLICKABLE}
          fontSize={12}
          h="30px"
          w="150px"
        >
          <HStack>
            <Icon
              as={
                selectedSortingMechanism.orderBy.includes('asc')
                  ? ChevronUpIcon
                  : ChevronDownIcon
              }
            />

            <Text fontSize={12}>
              {sortToDisplay(selectedSortingMechanism.sortingType)}
            </Text>
          </HStack>
        </MenuButton>

        <MenuList zIndex={MENU_LIST_ZINDEX}>
          <MenuOptionGroup
            key={
              selectedSortingMechanism.sortingType +
              '_' +
              selectedSortingMechanism.orderBy
            }
            value={
              selectedSortingMechanism.sortingType +
              '_' +
              selectedSortingMechanism.orderBy
            }
          >
            {menuItems}
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    </HStack>
  )
}

export default SortDropdown
