import { getTimestampDisplay } from '@/utils/time'
import { Text } from '@chakra-ui/react'

const TableTimestamp = ({ date }) => {
  if (!date) return <></>
  return (
    <>
      <Text width="100px">{getTimestampDisplay(date)}</Text>
    </>
  )
}

export default TableTimestamp
